/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import {
  Users as UsersIcon
} from 'react-feather';
import { BeakerIcon, CommentDiscussionIcon, LightBulbIcon, RocketIcon, GearIcon } from '@primer/octicons-react';

import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //     {
  //       title: 'Dashboard Alternative',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard-alternative'
  //     }
  //   ]
  // },
  {
    subheader: '',
    items: [
      {
        title: 'Programs',
        icon: RocketIcon,
        href: '/app/management/challenges',
        items: [
          {
            title: 'List Programs',
            href: '/app/management/challenges'
          },
          {
            title: 'Create Program',
            href: '/app/management/challenges/create'
          }
        ]
      },
      {
        title: 'Recipes',
        icon: BeakerIcon,
        href: '/app/management/recipes',
        items: [
          {
            title: 'List Recipes',
            href: '/app/management/recipes'
          },
          {
            title: 'Create Recipes',
            href: '/app/management/recipes/create'
          },
          {
            title: 'Recipes Type Manager',
            href: '/app/management/recipes/manage'
          }
        ]
      },
      {
        title: 'Hacks',
        icon: LightBulbIcon,
        href: '/app/management/tips',
        items: [
          {
            title: 'List Hacks',
            href: '/app/management/tips'
          },
          {
            title: 'Create Hack',
            href: '/app/management/tips/create'
          },
          {
            title: 'Hacks Manager',
            href: '/app/management/tipsManager'
          }
        ]
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Users',
            href: '/app/management/customers'
          },
          {
            title: 'Create Users',
            href: '/app/management/customers/create'
          }
        ]
      },
      {
        title: 'Community',
        icon: CommentDiscussionIcon,
        href: '/app/management/discussion',
        items: [
          {
            title: 'List Community',
            href: '/app/management/discussion'
          }
        ]
      },
      {
        title: 'Settings',
        icon: GearIcon,
        href: '/app/management/settings',
        items: [
          {
            title: 'Settings',
            href: '/app/management/settings'
          }
        ]
      },
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box> */}
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              // subheader={
              //   <ListSubheader disableGutters disableSticky>
              //     {config.subheader}
              //   </ListSubheader>
              // }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
