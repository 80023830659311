/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="app/management/recipes" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/create',
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/recipes',
        component: lazy(() => import('src/views/management/RecipeListView'))
      },
      {
        exact: true,
        path: '/app/management/recipes/create',
        component: lazy(() => import('src/views/management/RecipeCreateView'))
      },
      {
        exact: true,
        path: '/app/management/recipes/manage',
        component: lazy(() => import('src/views/management/RecipieManager'))
      },
      {
        exact: true,
        path: '/app/management/recipes/:recipeId/edit',
        component: lazy(() => import('src/views/management/RecipeEditView'))
      },
      {
        exact: true,
        path: '/app/management/tips',
        component: lazy(() => import('src/views/management/TipsListView'))
      },
      {
        exact: true,
        path: '/app/management/tipsManager',
        component: lazy(() => import('src/views/management/tipsManager'))
      },
      {
        exact: true,
        path: '/app/management/tips/:tipId/edit',
        component: lazy(() => import('src/views/management/TipsEditView'))
      },
      {
        exact: true,
        path: '/app/management/tips/create',
        component: lazy(() => import('src/views/management/TipsCreateView'))
      },
      {
        exact: true,
        path: '/app/management/challenges',
        component: lazy(() => import('src/views/management/ChallengesListView'))
      },
      {
        exact: true,
        path: '/app/management/challenges/create',
        component: lazy(() => import('src/views/management/ChallengesCreateView'))
      },
      {
        exact: true,
        path: '/app/management/challenges/:challengeId/slides',
        component: lazy(() => import('src/views/management/ChallengesEditView'))
      },
      {
        exact: true,
        path: '/app/management/challenges/:challengeId/edit',
        component: lazy(() => import('src/views/management/ChallengesCreateView'))
      },
      {
        exact: true,
        path: '/app/management/challenges/:challengeId/participants',
        component: lazy(() => import('src/views/management/ChallengesCheckParticipants'))
      },
      {
        exact: true,
        path: '/app/management/challenges/:challengeId/reflections/:userId/:filter',
        component: lazy(() => import('src/views/management/ChallangeReflection'))
      },
      {
        exact: true,
        path: '/app/management/challenges/:challengeId/reflections/:userId/',
        component: lazy(() => import('src/views/management/ChallangeReflection'))
      },
      {
        exact: true,
        path: '/app/management/discussion',
        component: lazy(() => import('src/views/management/DiscussionListView'))
      },
      {
        exact: true,
        path: '/app/management/discussion/create',
        component: lazy(() =>
          import('src/views/management/DiscussionCreateView')
        )
      },
      {
        exact: true,
        path: '/app/management/discussion/:discussionId/edit',
        component: lazy(() => import('src/views/management/DiscussionEditView'))
      },
      {
        exact: true,
        path: '/app/management/discussion/:discussionId/forum',
        component: lazy(() => import('src/views/management/DiscussionForumView'))
      },
      {
        exact: true,
        parth: '/app/management/settings',
        component: lazy(() => import('src/views/management/SettingsCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  console.log('inside routes');
  return renderRoutes(routesConfig);
}

export default Routes;
