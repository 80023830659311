import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      style={{
        height: 50,
        width: 50,
        borderRadius: 50
      }}
      {...props}
    />
  );
}

export default Logo;
